@import '../../styles/sharedStyles';
@import url('https://fonts.googleapis.com/css2?family=Alata&family=Anton&display=swap');

.container {
    background-color: white !important;
    // padding-top: rem(20) !important;
}

.brandingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-left: rem(13);
}
.menuContainerBlock{
    padding-top: rem(20);
    width: 100%;
}

.menu_container {
  padding-top: 0;
}

.brandLogo {
  width: 100%;
  height: auto;
}

.brandName {
    font-family: Anton;
    text-align: center;
    font-weight: $fontWeightNormal;
    color: $taylorFarmsBrandGreen;
}

.css-1c35hjw-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
}

.menuHeadingBlock {
  color: $taylorFarmsMenuItemsColor;
  display: flex;
  align-items: center;
}

.menuHeading {
  padding-left: rem(16);
  font-weight: $fontWeightSemiBold;
  margin: 0;
  font-size: 1rem;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.singleMenuHeading {
    padding: rem(12);
    text-decoration: none;
    display: flex;
    align-items: center;
    color: $taylorFarmsMenuItemsColor;
}

.singleMenuHeading:hover {
  color: $taylorFarmsBrandYellow;
}

.menuToggleContainer {
    display: flex;
    align-items: center;
    position: relative;
    right: rem(-8)
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapsedContainer {
  text-align: center;
}

.collapsedMenuContainer {
  font-size: rem(16);
  position: relative;
}

.collapsedMenuContainer i {
  font-size: rem(24);
}

.collapsedMenuItems {
  display: block;
  padding: rem(16) 0;
  color: black;
}

.collapsedMenuItems:hover {
  color: $taylorFarmsBrandYellow;
}

.collapsedBrandName {
  margin-top: 0;
}

.menuIcon{
  font-size: 1.25rem;
}

.active {
  color: $taylorFarmsBrandYellow;
  background-color: $taylorFarmsBackgroundColor;
  font-weight: $fontWeightSemiBold;
  border-left: rem(5) solid $taylorFarmsBrandYellow !important ;
}

.menuPopUp {
  position: absolute;
  left: rem(104);
  top: 0;
  background-color: white;
  padding: rem(10);
  box-shadow: 0 rem(20) rem(25) rem(-5) #0000001a;
  width: max-content;
  text-align: left;
  z-index: 1000;
}

.toggleIcon, .toggleIcon:hover{
    background-color: $taylorFarmsBrandYellow !important;
    color: white !important;
    box-shadow: 0 rem(20) rem(25) rem(-5) #0000001A !important;
    position: absolute !important;
    right: rem(-8) !important;
}

.submenuContainerBlock{
    padding: 0 !important;
}
.accordionHeading{
    min-height: rem(48);
}
