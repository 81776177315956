
.searchboxwrapper {
    text-align: left;
    position: relative;
  }
  .search {
    width: 100%;
    text-align: left;
    position: relative;
  }
  .search-box {
    width: 100%;
    height: 123px;
    padding: 10px;
    background-color: white;
    border: 1px solid rgb(209, 213, 219);
    border-radius: 6px;
  }
  .search-box:hover{
    border: 1px solid #ffc107;
  }
  
  ul.options {
    display: block;
    list-style: none;
    width: auto;
    transition: width 0.3s;
    margin: auto;
    position: relative;
  }
  
  ul.options li {
    display: block;
    background: white;
    margin: 10px auto;
    padding: 10px;
    width: auto;
    border-radius: 2px;
  }
  ul.options li:hover {
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s all;
  }
  
  ul.options li.option-active {
    background: whitesmoke;
  }
  .no-options {
    color: white;
  }
  .disabledFields {
    opacity: 0.6;
  }
  .options_Container{
    height: 150px;
    overflow-y: scroll;
  }