@import '../../styles/sharedStyles';

.formLabel {
  color: $taylorFarmsFormLabelColor;
}

.dropdown {
  height: rem(40);
  width: 75%;
  // width: 100%;
  margin: rem(0) rem(20);
}

.fileUploadContainer {
  width: 100%;
  border-style: dashed;
  border-color: $taylorFarmsBrandYellow;
  min-height: rem(220);
  border-radius: rem(20);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: rem(10);
}



@media screen and (min-width: 768px) {
  .fileUploadBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}


.creatFormButnContainer {
   padding: 0;
}

.textAreaContainer {
  height: rem(185);
  width: 100%;
  display: block;
  border-color: $taylorFarmsPageHeadingColor;
  resize: none;
}

.btnBlock {
  padding: 0 rem(20);
}

.ashFont {
  color: $taylorFarmsPageHeadingColor;
  margin-bottom: rem(12);
}

.primaryBtn {
  background-color: $taylorFarmsBrandYellow;
  color: black;
  border: none;
  width: 100%;
  padding: rem(10);
}

.secondaryBtn {
  background-color: white;
  color: $taylorFarmsBrandGreen;
  border: $taylorFarmsBrandGreen solid rem(1);
}

.primaryBtn:focus,
.secondaryBtn:focus, .inputField:focus, .dropdown:focus {
  box-shadow: none;
}

.uploadedExcel {
  background-color: #F0F9FF;
  font-size: 16px;
  color: #0EA5E9;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.uploadedExcelTitle {
  padding: 0 10px;
  margin: 0px;
}
.textAreaBlock {
  width: 100%;
  // min-height: rem(10);
}
.inputField {
  height: rem(38);
  margin: 0 rem(10);
  width: 60%;
}
@media (max-width: 480px) and (min-width: 300px) {
  .inputField {
   width: 100% !important;
  
  }
}

.errorMsg {
  color: red;
  font-size: rem(12);
  padding: rem(10) 0;
  text-align: center;
  float: right;
}


.errorContainer{
  background-color: $taylorFarmsErrorContainerColor;
  color: $taylorFramsErrorColor;
  padding: rem(15);
  border-radius: rem(8);
  max-width: fit-content;
}

.alignRight {
  text-align: end;
}

.clearButtonAndErrorContainer {
  display: flex;
  align-items: start;
}

.dropdownContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
