@import '../../styles/sharedStyles';

.addFunctionButton:hover {
    background-color: $taylorFarmsHoverGreen;
}

.addFunctionButton {
    height: rem(48);
    border-radius: rem(4);
    background-color: $taylorFarmsBrandGreen;
    border: none;
    color: white;
}

.addFunctionButton span {
    font-weight: normal;
    font-size: rem(16);
}
.fileIcon {
    background-color: #0fa4e9;
    color: white;
    border: 0px solid;
    padding: 10px;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 5px;
    font-size: 10px;
  }

.editBtn {
    color: black;
}
.viewBtn {
   color: $taylorFarmsDarkBlue;
}
.dltBtn {
    color: red;
}
.btns {
    background-color: white;
    border: none;
}


@media screen and (min-width: 380px){
    .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
    }
    }
    
    .formLabel {
        color: $taylorFarmsMenuItemsColor;
        font-weight: normal;
    }
    
    .filterDropdown {
        margin: 0 rem(20);
        width: rem(193);
        height: rem(48);
        padding: 6px;
    }
    .searchBar {
        text-align: end;
    }
    .noBorder{
        border-left: none;
        height: 3rem;
      }

      .resetBtn, .resetBtn:hover {
        background-color: $taylorFarmsPageHeadingColor !important;
        color: white !important;
        border: none !important;
      }

      .lineitemsBtn{
        border-radius: 15px;
      }
      .lineitemsBtn span{
        font-size: 10px;
      }


      .prpceedBtn, .prpceedBtn:hover{
        border-radius: 4px;
      background-color: $taylorFarmsDarkenBlue !important;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      border: none;
      margin: 5px;
      }

      .reprocessBtn, .reprocessBtn:hover{
        border-radius: 4px;
        padding: 4px;
      background-color: #6C757D !important;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      border: none;
      margin: 5px;
      }