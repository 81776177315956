@import '../../styles/sharedStyles';

.primaryBtn {
    background-color: $taylorFarmsBrandYellow;
    color: black;
    border: none;
    width: 100%;
    height: 100%;
  }
  
  .secondaryBtn {
    background-color: white;
    color: $taylorFarmsBrandGreen;
    border: $taylorFarmsBrandGreen solid rem(1);
    height: 100%;
  }
  .primaryBtn span, .secondaryBtn span, .tirnaryButton span  {
    font-weight: 500;
}

.tirnaryButton {
    padding: rem(12) 0;
}
.tirnaryButton {
    background: none;
    border: none;
    color: $taylorFarmsPageHeadingColor;
  }
  
  .primaryBtn:focus,
  .secondaryBtn:focus, .inputField:focus, .dropdown:focus {
    box-shadow: none;
  }

  .card{
    position: relative;
    background-color: white;
    padding: 0 rem(20);
    border-radius: rem(8);
    min-height: rem(200);
    box-shadow: rem(0) rem(4) rem(8) $boxShadowColor;
    margin-bottom: rem(10);
  }
  .headerContainer .p-datatable .p-datatable-header{
    background-color: white !important;
    border: none !important;
  }
  .card {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: rem(8);
    background-color: white;
    margin:rem(10);
    min-height: fit-content;
  }
  @media screen and (min-width: 768px) {
    .orderSummaryContainer{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .cartIcon{
    color: $taylorFarmsPurpleColor;
    background-color: $taylorFarmsLightPurple;
    padding: rem(8);
    border-radius: rem(8);
  }
  .dbIcon{
    color:#533fe4;
    background-color: #d7ffc3;
    padding: rem(8);
    border-radius: rem(8);
  }
  .cardTitle{
    font-size: rem(16);
    font-weight: 500;
  }
  .cardPercentage{
    font-size: rem(24);
    font-weight: 700;
  }
  .green > [class~="p-progressbar-value"]{
    background:#22C55E !important;
  }
  .red > [class~="p-progressbar-value"]{
    background:#EF4444 !important;
  }
  .blue > [class~="p-progressbar-value"]{
    background:#0EA5E9 !important;
  }
  .headerContainer > [class~="p-datatable-header"]{
    background: white !important;
  }
  .tag{
    font-weight: 500;
    border-radius: rem(32);
}