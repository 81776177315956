@import '../../styles/sharedStyles';

.formLabel {
  color: $taylorFarmsFormLabelColor;
  font-size: rem(15);
}

.formLabelContainer {
  padding: rem(10) 0;
  height: 2.469rem;
}

.formControl {
  width: 100%;
}

.primaryBtn {
  background-color: $taylorFarmsBrandYellow;
  color: black;
  border: none;
}

.resetBtn {
  background-color: $taylorFarmsPageHeadingColor;
  color: white;
  border: none;
}

.copyBtn {
  background-color: $taylorFarmsPageHeadingColor;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 400;
}

.enableDownloadIcon {
  color: Green;
  cursor: pointer;
}

.errorMsg {
  color: red;
  display: flex;
  text-align: center;
  margin: rem(10) 0;
}

.formLabel {
  color: $taylorFarmsMenuItemsColor;
  font-weight: normal;
}

.filterDropdown {
  margin: 0 rem(20);
  width: rem(193);
  height: rem(48);
}

.searchBar {
  text-align: end;
}

.noBorder {
  border-left: none;
}

.editBtn {
  color: black;
}

.btns {
  // background-color: rgb(248, 249, 250);
  background-color: white;
  border: none;
}

.btnsDuplicate {
  background-color: #fef0cd;
  border: none;
}

.dropdownDuplicate {
  background-color: #fef0cd;
}
