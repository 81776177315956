@import '../../styles/sharedStyles';

.filterContainer {
    background-color: $taylorFarmstableHeaderColor;
    padding: rem(16);
}

.filterBtn {
    background-color: white;
}

.filterBtn span {
    font-weight: normal;
}

.errorContainer{
    background-color: $taylorFarmsErrorContainerColor;
    color: $taylorFramsErrorColor;
    padding: rem(15);
    border-radius: rem(8);
    margin-bottom: rem(18);
    max-width: fit-content;
}

.secondaryBtn {
    background-color: white;
    color: $taylorFarmsBrandGreen;
    border: $taylorFarmsBrandGreen solid rem(1);
    width: 100%;
    padding: rem(10);
  }
  .secondaryBtn:hover {
    background-color: $taylorFarmsBrandGreen;
    color: white;
    border: $taylorFarmsBrandGreen solid rem(1);
    width: 100%;
    padding: rem(10);
  }
  .greyedButton{
    background-color:#E5E5E5;
    color: #475569;
    border: none;
    width: 100%;
    padding: rem(10);
  }

  .tirnaryButton {
    background: none;
    border: none;
    color: $taylorFarmsPageHeadingColor;
    padding: rem(10);
  }

.tag{
    font-weight: 500;
    border-radius: rem(32);
}

.primaryBtn {
    background-color: $taylorFarmsBrandYellow;
    color: black;
    border: none;
    width: 100%;
    padding: rem(10);
  }

.filterControls {
   width: 100%;
}

.primaryBtn:focus, .dropdown:focus {
    box-shadow: none;
  }

.buttonContainer {
    text-align: center;
}

.primaryBtn span, .secondaryBtn span, .tirnaryButton span  {
    font-weight: 500;
}

// .tirnaryButton {
//     padding: rem(12) 0;
// }
.footerContainer {
  padding: 0;
}
.orderSummary_Band{
  padding: rem(16);
  color: $taylorFarmsBlackDarkColor;
  display: flex;
  align-items: center;
  margin-bottom: rem(20);
}
.orderSummary_Band span{
  padding: 0 rem(16);
}
// .orderSummaryTableContainer{
//   // padding: rem(16);
// }
.green {
  background-color: #e8fcef;
}
.red {
  background-color: #EF4444;
}
.xmlListHeading{
  color: #27272a;
    background: #f8fafc;
    padding: rem(12);
    border-bottom: rem(1) solid #e2e8f0;
    border-top: rem(1) solid #e2e8f0;
    font-weight: 500;
    font-size: rem(14);
}
.xmlList {
  list-style-type: none;
    padding: rem(0);
    margin: rem(0);
}
.xmlListItem{
  color: #27272a;
    padding: rem(12);
    border-bottom: rem(1) solid #e2e8f0;
    border-top: rem(1) solid #e2e8f0;
    font-weight: 500;
    font-size: rem(14);
}
.confirmationText{
  // display: block;
  margin: rem(14) 0;
  font-size: rem(12);
  color: #3a3541;
}
.confirmationMail{
  text-decoration: none;
  color: #3b82f6;
}

.downloadIcon {
  background-color: white;
  color: $taylorFarmsPageHeadingColor;
  border: 0px solid;
  padding: 15px;
  cursor: not-allowed;
  
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 5px;
  font-size: 16px;
}
.enableDownloadIcon {
  color: Green;
  cursor: pointer;
}
.noBorder{
  border-left: none;
}