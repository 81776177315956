@import '../../styles/sharedStyles';

.cardContainer {
  position: relative;
  background-color: white;
  padding: 0 rem(20);
  border-radius: rem(8);
  min-height: rem(200);
  box-shadow: rem(0) rem(4) rem(8) $boxShadowColor;
  margin-bottom: rem(10);
}
