@import '../../styles/sharedStyles';

.addFunctionButton {
    color: $taylorFarmsDarkBlue;
    background-color: white;
    border: none;
}

.addFunctionButton span {
    font-weight: normal;
}

.addFunctionButton:hover {
    background-color: $taylorFarmsLightBlue;
}

.tab a{
    width:100%;
    height: rem(54);
    padding-top: rem(15);
    padding-bottom: rem(15);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(8);
    display: inline-flex;
    color: $taylorFarmsFormLabelColor;
    border-radius: 0;
    font-size: rem(16);
    font-weight: 600;
}

.activeTab a {
    background: $taylorFarmsTabColor;
    border: 1px $taylorFarmsTabBorderColor solid;
}

.formLabel {
    color: $taylorFarmsFormLabelColor;
    font-size: rem(15)
}
.formLabelContainer {
    padding: rem(10) 0;
}

.InactiveTab a {
    background: $taylorFarmstableHeaderColor;
    border: 1px $taylorFarmsInactiveTabBorder solid;
}

.statusdropdown {
    margin: 0 rem(20);
    height: rem(48);
    padding: 6px;
}
.formControl{
width: 100%;
}
.checkBoxSpacing {
    padding: rem(8) 0;
}
.secondaryBtn {
    background-color: white;
    color: $taylorFarmsBrandGreen;
    border: $taylorFarmsBrandGreen solid rem(1);
    margin: rem(5);
  }

  .thirdBtn, .thirdBtn:hover {
    border-radius: 3px;
    background: $taylorFarmsPageHeadingColor;
    color: white;
  }

  .secondaryBtn:hover {
    background-color: $taylorFramsBrandGreenLight;
  }

  
.primaryBtn {
    background-color: $taylorFarmsBrandYellow;
    color: black;
    border: none;
    margin: rem(5);
  }
.tirnaryBtn{
    background-color: white;
    border: $taylorFarmsPageHeadingColor solid rem(1);
    color: $taylorFarmsPageHeadingColor;
    margin: rem(5);
}
.tirnaryBtn:hover {
    background-color: $taylorFarmsLightGrayColor ;
}
.primaryBtn span, .tirnaryBtn span {
    font-weight: normal;
}

  .Formheader {
    font-size: rem(20);
    font-weight: 500;
    color: $taylorFarmsPageHeadingColor;
    margin: rem(10) 0;
  }

  .errorMsg {
    color: red;
    display: flex;
    text-align: center;
    margin: rem(10) 0;
  }
  .hideSubmitBtns {
    visibility: hidden;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(14, 1fr); /* 4 columns */
    grid-template-rows: repeat(4, 1fr); /* 14 rows */
    // gap: 5px; /* Gap between grid items */
    /* Optional: Add styling for grid items */
    overflow: auto;
}
.headerBlock{
    background: #f8fafc;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Optional: Add styling for grid items */
.gridItem {
    padding: 10px;
    text-align: center;
    width: 15rem;
    border: 1px #E2E8F0 solid;
    color:black;
}
.tableInput {
    border: none;
}
// .disabledCheckBox >.p-checkbox .p-checkbox-box { 
//     background: #E2E8F0 !important;
// }
// .disabledCheckBox > [class~="p-checkbox p-checkbox-box"]{
//     background:#E2E8F0 !important;
//   }

.disabledCheckBox {
    margin: 0;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.disabledFields{
    opacity: 0.6 !important;
}

@media screen and (min-width: 768px) {
    .checkboxFields{
        display: flex;
        align-items: center;
        justify-content: left;
    }
}
.blob_heading{
color: #334155;
font-size: 20px;
font-weight: 600;
padding: 15px;
border: 1px solid #E2E8F0;
background: #F8FAFC;
margin: 0px;
}

.disableblob_Field{
    display: flex;
    align-items: start;
    height: 100%;
    padding-top: 30px; 
}
.groupFields{
    border: 1px solid black;
}
.function_name_detail{
    color: #2F4257;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
}
.groupFields{
    border: 1px solid #e2e8f0;
}
