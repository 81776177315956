@import '../../styles/sharedStyles';

.submenuContainer {
  padding: 0;
}

.submenuItem {
    text-decoration: none;
    color: $taylorFarmsMenuItemsColor;
    padding: rem(12);
    padding-left: rem(56);
    display: block;
}

.submenuWithOutPadding {
  padding-left: rem(5);
} 

.css-15v22id-MuiAccordionDetails-root {
  padding: rem(0);
}

.submenuItem:hover {
  color: $taylorFarmsBrandYellow;
}
.active {
  color: $taylorFarmsBrandYellow;
  background-color: $taylorFarmsBackgroundColor !important;
  display: block;
  font-weight: $fontWeightSemiBold;
  border-left: rem(5) solid $taylorFarmsBrandYellow;
  padding-left: rem(50);
}
