@import '../../styles/sharedStyles';

.navbarContainer {
  padding: rem(4) rem(0);
}
.navbarProfileContainer,
.profileSection {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-left: rem(15);
}
.navbarSecondGrid {
  text-align: right;
}
.userProfileInfo {
  padding: 0 rem(10);
}
.userName {
  font-size: rem(16);
  font-style: normal;
  font-weight: 400;
  line-height: rem(24); 
  margin: 0;
}
.email {
  font-size: rem(12);
font-style: normal;
font-weight: 400;
line-height: rem(16);
}
.profileIcon {
  cursor: pointer;
  color: $taylorFarmsPageHeadingColor;
  font-size: rem(20);
}
.darkCloud {
  color: $taylorFarmsPageHeadingColor;
}
.title {
  font-weight: $fontWeightBold;
  font-size: rem(30);
}
.userProfileIcon{
  color: white;
  padding: 0.6rem;
  border-radius: 100%;

}
.mojo_help_guide{
  text-decoration: none;
}
.help_text{
  color:  #334155;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px; 
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
}