$taylorFarmsBrandGreen: #22c55e;
$taylorFarmsBrandYellow: #faba15;
$taylorFarmsBackgroundColor: #f4f5f9;
$taylorFarmsMenuItemsColor: #334155;
$taylorFarmsPageHeadingColor: #64748b;
$taylorFarmsLightGrayColor:#e4e8ec;
$taylorFarmsFormLabelColor: #334155;
$taylorFarmstableHeaderColor: #f8fafc;
$taylorFarmsErrorContainerColor: #fef2f2;
$taylorFramsErrorColor: #ef4444;
$taylorFarmsExcelUploadContainerColor: #6B21A8;
$taylorFarmsHoverGreen: #15803d;
$taylorFarmsDarkBlue:#6366f1;
$taylorFarmsDarkenBlue: #00116B;
$taylorFarmsLightBlue:#eef2ff;
$taylorFarmsBrandYellowDark: #A16207;
$taylorFarmsDarkBlueDark: #0369A1;
$taylorFarmsTabColor: #FDE047;
$taylorFarmsTabBorderColor: #EAB308;
$taylorFarmsInactiveTabBorder: #E2E8F0;
$taylorFramsBrandGreenLight: #f0fdf4;
$taylorFarmsBlackDarkColor: #212121;
$taylorFarmsPurpleColor: #6e39cb;
$taylorFarmsLightPurple: #deccfe;
$boxShadowColor: #d3d3d3;
