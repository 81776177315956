@import '../../styles/sharedStyles';

.heading {
    // color: $taylorFarmsPageHeadingColor;
    // font-size: rem(24);
    // font-weight: 500;
    // text-shadow: 0.5px 0.5px 0.5px $taylorFarmsPageHeadingColor;
    /* Stroke effect */
    color: #2F4257;
font-family: Inter;
font-size: 20px;
font-weight: 600;
}

@media screen and (min-width: 380px) {
    .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}