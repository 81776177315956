@import '../../styles/sharedStyles';

.loginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(../../assets/backgroundImg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.loginSubContainer{
    padding: rem(30) rem(24);
}
.loginLogoContainer{
    text-align: right;
}
.brandLogo {
    width: rem(150);
    height: auto;
}
.mojoBrandLogo{
    height: 30px;
    width: 66px;
}

.brandName {
    font-size: rem(70);
    margin: 0;
    font-family: Anton;
    font-weight: $fontWeightNormal;
    color: $taylorFarmsBrandGreen;
}
.loginContainerBox{
    border: 1px solid #e2e8f0;
    padding: rem(32) rem(16);
}
.title{
    font-family: inter;
    text-align: center;
    color: #64748b;
}
.heading{
    font-size: rem(36);
    font-weight: 600;
}
.subHeading{
    font-size: rem(18);
    font-weight: 500;
}
.signInBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: rem(12);
    border: none;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.10), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
}
.signInBtn:hover{
    cursor: pointer;
}
.signInBtnLabel{
    padding: 0 rem(6);
    color: #0ea5e9;
    font-size: rem(20);
    font-weight: normal;
}
.btnContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}